var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "pb-2 mb-md-4"
  }, [_c('b-overlay', {
    attrs: {
      "show": _vm.loading || _vm.loadingRefetchBooking,
      "rounded": "sm",
      "no-fade": "",
      "variant": "light",
      "spinner-variant": "info",
      "spinner-type": "grow",
      "opacity": 0.9,
      "no-center": ""
    },
    scopedSlots: _vm._u([{
      key: "overlay",
      fn: function fn() {
        return [_c('div', {
          staticClass: "text-center py-2 mt-4"
        }, [_c('p', {
          staticClass: "font-weight-bolder text-airline",
          attrs: {
            "id": "cancel-label"
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('reservation.loading2')) + " ")]), _c('div', {
          staticClass: "text-center"
        }, [_c('b-spinner', {
          attrs: {
            "variant": "info"
          }
        })], 1)])];
      },
      proxy: true
    }])
  }, [_c('div', {
    staticClass: "d-flex flex-column gap-1 w-100 pb-50",
    staticStyle: {
      "position": "sticky",
      "top": "50px",
      "z-index": "10"
    }
  }, [_vm.isLoadBookingFail ? _c('BAlert', {
    staticClass: "px-2 py-50 mb-0",
    staticStyle: {
      "background-color": "#F8E4E4 !important"
    },
    attrs: {
      "show": "",
      "variant": "danger"
    }
  }, [_c('span', {
    staticClass: "font-weight-bolder"
  }, [_vm._v(" Đây là dữ liệu được lưu trên hệ thống Airdata. Booking hãng đã bị xoá. ")])]) : _vm._e(), _vm.isItinerariesSC ? _c('BAlert', {
    staticClass: "px-2 py-50 mb-0",
    staticStyle: {
      "background-color": "#F8E4E4 !important"
    },
    attrs: {
      "show": "",
      "variant": "danger"
    }
  }, [_c('span', {
    staticClass: "font-weight-bolder"
  }, [_vm._v(" Vé có hành trình đổi giờ bay mới (SC), vui lòng xác nhận giờ bay mới. ")])]) : _vm._e(), !_vm.loading && _vm.getBookingData && _vm.isLockedModify ? _c('BAlert', {
    staticClass: "px-2 py-50 mb-0",
    staticStyle: {
      "background-color": "#F8E4E4 !important"
    },
    attrs: {
      "show": "",
      "variant": "danger"
    }
  }, [_vm._v(" Hệ thống Vietnam Airlines khoá thanh toán, hoàn huỷ booking từ 23:30 đến hết 24:00 trong ngày !!! ")]) : _vm._e(), !_vm.loading && _vm.getBookingData && _vm.canIssueLimit === false && !_vm.isBookingOutSystem ? _c('BAlert', {
    staticClass: "px-2 py-50 mb-0",
    staticStyle: {
      "background-color": "#F8E4E4 !important"
    },
    attrs: {
      "show": "",
      "variant": "danger"
    }
  }, [_vm._v(" Tài khoản đại lý của bạn không có quyền thanh toán booking. Vui lòng liên hệ đại lý cấp trên. ")]) : _vm._e(), _vm.isPastDayBooking ? _c('BAlert', {
    staticClass: "px-2 py-50 font-weight-bolder mb-0",
    staticStyle: {
      "background-color": "#F8E4E4 !important"
    },
    attrs: {
      "show": "",
      "variant": "danger"
    }
  }, [_c('span', [_vm._v("Không thể thanh toán và chỉnh sửa vé qua ngày bay.")])]) : _vm.isBookingOutSystem ? _c('BAlert', {
    staticClass: "px-2 py-50 font-weight-bolder mb-0",
    staticStyle: {
      "background-color": "#F8E4E4 !important"
    },
    attrs: {
      "show": "",
      "variant": "danger"
    }
  }, [_vm.isBookingDeactive ? _c('span', [_vm.isRoleF1 ? _c('div', {
    staticClass: "d-flex-between"
  }, [_c('div', [_c('div', [_vm._v("Booking đã được đồng bộ vào hệ thống.")]), _c('div', [_vm._v("Vui lòng mở khoá để thanh toán và chỉnh sửa booking.")])]), _c('BButton', {
    attrs: {
      "variant": "success"
    },
    on: {
      "click": _vm.handleUnlockOutsideBooking
    }
  }, [_vm._v(" Mở khoá ")])], 1) : _c('div', [_c('div', [_c('div', [_vm._v("Booking đã được đồng bộ vào hệ thống.")]), _c('div', [_vm._v("Vui lòng liên hệ Booker mở khoá để thanh toán và chỉnh sửa booking.")])])])]) : _c('span', [_vm._v("Không thể thanh toán và chỉnh sửa booking ngoài.")])]) : _vm._e()], 1), _vm.getBookingData ? _c('b-tabs', {
    staticClass: "mb-0",
    attrs: {
      "nav-class": "mb-50",
      "lazy": ""
    },
    scopedSlots: _vm._u([{
      key: "tabs-end",
      fn: function fn() {
        return [_c('li', {
          staticClass: "nav-item ml-auto d-flex-center flex-wrap gap-2 mt-25 mt-md-0",
          class: _vm.isMobileView ? 'w-100 justify-content-center' : '',
          attrs: {
            "role": "presentation"
          }
        }, [_vm.suggestVisible ? _c('b-button', {
          staticClass: "px-50 px-md-1 text-nowrap text-truncate pulse-button",
          attrs: {
            "variant": "primary",
            "pill": "",
            "size": "sm"
          },
          on: {
            "click": function click() {
              return _vm.$bvModal.show('suggest-shop-modal');
            }
          }
        }, [_c('div', {
          staticClass: "d-flex-center gap-1"
        }, [_c('feather-icon', {
          staticClass: "mr-50 d-none d-md-inline",
          attrs: {
            "icon": "SearchIcon",
            "size": "14"
          }
        }), _vm._v(" " + _vm._s(_vm.$t('suggestShopVJ')) + " ")], 1)]) : _vm._e(), _vm.isRoleF2 && _vm.getBookingData.airlines.split(' | ').some(function (e) {
          return _vm.airlineSupportsCC.includes(e);
        }) ? _c('b-button', {
          attrs: {
            "variant": "primary",
            "pill": "",
            "size": "sm"
          },
          on: {
            "click": function click() {
              return _vm.$bvModal.show('create-support-modal');
            }
          }
        }, [_c('div', {
          staticClass: "d-flex-center gap-1"
        }, [_vm._v(" " + _vm._s(_vm.$t(_vm.supportReqId ? 'supportReq.btn.editReq' : 'supportReq.form.title')) + " ")])]) : _vm._e(), !_vm.hideFeature ? _c('b-button', {
          staticClass: "px-50 px-md-1 text-nowrap text-truncate",
          attrs: {
            "variant": "outline-primary",
            "pill": "",
            "size": "sm",
            "disabled": _vm.isBookingOutSystem
          },
          on: {
            "click": function click($event) {
              return _vm.editNote($event.target);
            }
          }
        }, [_c('div', {
          staticClass: "d-flex-center gap-1"
        }, [_c('IAmIcon', {
          attrs: {
            "icon": "pencil",
            "size": "15"
          }
        }), _vm._v(" " + _vm._s(_vm.$t('reservation.note')) + " ")], 1)]) : _vm._e(), _vm.isRoleF1 && !_vm.hideFeature && !_vm.isHideFunctionButton ? _c('b-button', {
          staticClass: "px-50 px-md-1 text-nowrap text-truncate",
          attrs: {
            "variant": "primary",
            "pill": "",
            "size": "sm",
            "disabled": _vm.isBookingOutSystem
          },
          on: {
            "click": function click(e) {
              return _vm.openAssignBooking(e, _vm.getBookingData);
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('reservation.assignBooking.title')) + " ")]) : _vm._e(), _vm.getBookingData.status === 'HOLD' && ['VJ'].includes(_vm.getBookingData.source) && !_vm.hideFeature && !_vm.isHideFunctionButton ? _c('b-button', {
          staticClass: "px-50 px-md-1 text-nowrap text-truncate",
          attrs: {
            "variant": "primary",
            "pill": "",
            "size": "sm",
            "disabled": !_vm.canAccess('booking.issueTicket') || _vm.isBookingOutSystem || !_vm.canIssueLimit
          },
          on: {
            "click": function click(e) {
              return _vm.openSetIssue(e, _vm.getBookingData);
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.getBookingData.autoIssueTime ? _vm.$t('reservation.historyActions.SET_AUTO_ISSUE_AND_CANCEL') : _vm.$t('reservation.historyActions.SET_AUTO_ISSUE')) + " ")]) : _vm._e(), !['VN1A_MT', 'VN1A'].includes(_vm.getBookingData.source) && _vm.canSetLowFare(_vm.getBookingData) && !_vm.hideFeature && !_vm.isHideFunctionButton ? _c('b-button', {
          staticClass: "px-50 px-md-1 text-nowrap text-truncate",
          attrs: {
            "variant": "primary",
            "pill": "",
            "size": "sm",
            "disabled": _vm.isBookingOutSystem || !_vm.canIssueLimit
          },
          on: {
            "click": function click($event) {
              return _vm.handleShowModalLowFareTracking(_vm.getBookingData);
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('reservation.lowFare.lowfareTracking')) + " ")]) : _vm._e(), _vm.isF3canSendMailAirline && !_vm.isHideEmailButton && !_vm.hideFeature && !_vm.isHideFunctionButton ? _c('b-button', {
          staticClass: "px-50 px-md-1 text-nowrap text-truncate",
          attrs: {
            "variant": "primary",
            "pill": "",
            "size": "sm",
            "disabled": _vm.getBookingData.status === 'CANCEL' || !_vm.canAccess('booking.sendEmail') || _vm.isBookingOutSystem || !_vm.canIssueLimit
          },
          on: {
            "click": _vm.handleShowModalSendEmail
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('reservation.historyActions.SEND_EMAIL')) + " ")]) : _vm._e(), _vm.getBookingData.isActive !== undefined && !_vm.hideFeature && !_vm.isHideFunctionButton ? _c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(113, 102, 240, 0.15)',
            expression: "'rgba(113, 102, 240, 0.15)'",
            modifiers: {
              "400": true
            }
          }, {
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover.v-primary.window",
            modifiers: {
              "hover": true,
              "v-primary": true,
              "window": true
            }
          }],
          staticClass: "btn-icon p-50",
          attrs: {
            "variant": "primary",
            "disabled": _vm.isBookingOutSystem,
            "title": _vm.isMobileView ? '' : _vm.getBookingData.isActive ? _vm.$t('reservation.hideBooking') : _vm.$t('reservation.showBooking'),
            "size": "sm",
            "pill": ""
          },
          on: {
            "click": function click($event) {
              return _vm.showConfirmToggleHideBooking(_vm.getBookingData, !_vm.getBookingData.isActive);
            }
          }
        }, [_vm.getBookingData.isActive ? _c('feather-icon', {
          attrs: {
            "icon": "EyeIcon",
            "size": "18"
          }
        }) : _c('feather-icon', {
          attrs: {
            "icon": "EyeOffIcon",
            "size": "18"
          }
        })], 1) : _vm._e(), !_vm.isHideFunctionButton ? _c('b-button', {
          staticClass: "btn-danger px-50 px-md-1 text-nowrap text-truncate",
          attrs: {
            "pill": "",
            "variant": "danger",
            "size": "sm"
          },
          on: {
            "click": _vm.toggleHideFeature
          }
        }, [_vm._v(" " + _vm._s(_vm.$t("reservation.".concat(_vm.hideFeature ? 'showFeature' : 'hideFeature'))) + " ")]) : _vm._e()], 1)];
      },
      proxy: true
    }], null, false, 2063219242)
  }, [_c('b-tab', {
    attrs: {
      "title": _vm.$t('reservation.bookingDetail'),
      "title-link-class": "font-medium-1 fw-800",
      "active": ""
    }
  }, [_c('DetailComponent', {
    attrs: {
      "reservations-data": _vm.getBookingData,
      "shorten-booking-text": _vm.bookingText,
      "is-booking-out-system": _vm.isBookingOutSystem,
      "is-hide-function-button": _vm.isHideFunctionButton,
      "can-issue-limit": _vm.canIssueLimit,
      "is-locked-modify": _vm.isLockedModify
    }
  })], 1), _c('b-tab', {
    attrs: {
      "title-link-class": "font-medium-1 fw-800",
      "title": _vm.$t('reservation.shortenBooking.titleBooking')
    },
    on: {
      "click": _vm.getShortenBookingHandle
    }
  }, [_vm.getBookingData.source.includes('1S') ? _c('b-tabs', {
    staticClass: "mb-1",
    attrs: {
      "pills": ""
    }
  }, [_c('b-tab', {
    attrs: {
      "title-link-class": "font-medium-1 fw-700",
      "title": _vm.$t('flight.shortenBooking'),
      "active": ""
    }
  }, [_c('div', {
    staticClass: "my-1"
  }, [_c('ShortenBooking', {
    attrs: {
      "text-type": 'Booking',
      "reservations-data": _vm.getBookingData,
      "shorten-text": _vm.bookingText,
      "loading": _vm.loadingShortenBooking
    },
    on: {
      "get-shorten-booking-handle": _vm.getShortenBookingHandle
    }
  })], 1)]), _vm.baggagesText ? _c('b-tab', {
    attrs: {
      "title-link-class": "font-medium-1 fw-700",
      "title": _vm.$t('flight.shortenBaggages')
    }
  }, [_c('div', {
    staticClass: "my-1"
  }, [_c('ShortenBooking', {
    attrs: {
      "text-type": 'Baggages',
      "is-history": true,
      "reservations-data": _vm.getBookingData,
      "shorten-text": _vm.baggagesText,
      "loading": _vm.loadingShortenBooking
    },
    on: {
      "get-shorten-booking-handle": _vm.getShortenBookingHandle
    }
  })], 1)]) : _vm._e(), _vm.fareRulesText ? _c('b-tab', {
    attrs: {
      "title-link-class": "font-medium-1 fw-700",
      "title": _vm.$t('flight.shortenFareRules')
    }
  }, [_c('div', {
    staticClass: "mt-1 mb-5 pb-2"
  }, [_c('ShortenBooking', {
    attrs: {
      "text-type": 'FareRules',
      "is-history": true,
      "reservations-data": _vm.getBookingData,
      "shorten-text": _vm.fareRulesText,
      "loading": _vm.loadingShortenBooking
    },
    on: {
      "get-shorten-booking-handle": _vm.getShortenBookingHandle
    }
  })], 1)]) : _vm._e()], 1) : _c('div', {
    staticClass: "my-1"
  }, [_c('ShortenBooking', {
    attrs: {
      "text-type": 'Booking',
      "reservations-data": _vm.getBookingData,
      "shorten-text": _vm.bookingText,
      "loading": _vm.loadingShortenBooking
    },
    on: {
      "get-shorten-booking-handle": _vm.getShortenBookingHandle
    }
  })], 1)], 1), _vm.getBookingData.source.includes('1S') || _vm.historyText ? _c('b-tab', {
    attrs: {
      "title-link-class": "font-medium-1 fw-800",
      "title": _vm.$t('reservation.history.title')
    }
  }, [_c('ShortenBooking', {
    attrs: {
      "text-type": 'History',
      "is-history": true,
      "reservations-data": _vm.getBookingData,
      "shorten-text": _vm.historyText,
      "loading": _vm.loadingShortenBooking
    },
    on: {
      "get-shorten-booking-handle": _vm.getShortenBookingHandle
    }
  })], 1) : _vm._e(), _vm.canIssueLimit && _vm.getBookingData && _vm.getBookingData.isDomestic && !_vm.isEmpty(_vm.getBookingData.itineraries) ? _c('b-tab', {
    attrs: {
      "title-link-class": "font-medium-1 fw-800",
      "title": _vm.$t('reservation.dkve')
    }
  }, [_c('FareRules', {
    attrs: {
      "reservations-data": _vm.getBookingData
    }
  })], 1) : _vm._e(), _vm.canIssueLimit && _vm.getBookingData && ['1G'].includes(_vm.getBookingData.source) && !_vm.isEmpty(_vm.getBookingData.itineraries) ? _c('b-tab', {
    attrs: {
      "title-link-class": "font-medium-1 font-weight-bolder",
      "title": _vm.$t('reservation.dkve')
    },
    on: {
      "click": function click($event) {
        return _vm.getFareRulesHandle(_vm.getBookingData);
      }
    }
  }, _vm._l(_vm.dataFareRules, function (tripData, index) {
    return _c('BCard', {
      key: index,
      staticClass: "border",
      attrs: {
        "header-bg-variant": "light-info",
        "header-class": "py-75 mb-75"
      },
      scopedSlots: _vm._u([{
        key: "header",
        fn: function fn() {
          return [_c('div', {
            staticClass: "fw-700 text-airline"
          }, [_vm._v(" " + _vm._s(tripData.trip[0].departure.iataCode) + "-" + _vm._s(tripData.trip[tripData.trip.length - 1].arrival.iataCode) + " (" + _vm._s(_vm.convertISODateTime(tripData.trip[0].departure.at, tripData.trip[0].departure.timeZone).date) + ") ")])];
        },
        proxy: true
      }], null, true)
    }, [_c('ShortenBooking', {
      attrs: {
        "text-type": 'FareRules',
        "is-history": false,
        "reservations-data": _vm.getBookingData,
        "shorten-text": tripData.fareRules,
        "loading": _vm.loadingShortenBooking
      },
      on: {
        "get-shorten-booking-handle": function getShortenBookingHandle($event) {
          return _vm.getFareRulesHandle(_vm.getBookingData);
        }
      }
    })], 1);
  }), 1) : _vm._e()], 1) : _vm._e(), _vm.getBookingData ? _c('ModalSms', {
    attrs: {
      "ticket-data": _vm.getBookingData
    }
  }) : _vm._e(), _vm.getBookingData ? _c('AutoIssueModal', {
    attrs: {
      "booking-data": _vm.getBookingData
    },
    on: {
      "update:bookingData": function updateBookingData($event) {
        _vm.getBookingData = $event;
      },
      "update:booking-data": function updateBookingData($event) {
        _vm.getBookingData = $event;
      },
      "refetch-data": _vm.refetchBookingData
    }
  }) : _vm._e(), _c('SendEmailModal'), _vm.getBookingData && !_vm.isEmpty(_vm.getBookingData.bookingRequest) && _vm.sourceCanSetLowFare ? _c('ModalLowFareTracking', {
    attrs: {
      "booking-data": _vm.getBookingData
    }
  }) : _vm._e(), _vm.getBookingData ? _c('AssignBookingModal', {
    attrs: {
      "booking-data": _vm.getBookingData
    },
    on: {
      "update:bookingData": function updateBookingData($event) {
        _vm.getBookingData = $event;
      },
      "update:booking-data": function updateBookingData($event) {
        _vm.getBookingData = $event;
      },
      "refetch-data": _vm.refetchBookingData
    }
  }) : _vm._e(), _vm.getBookingData ? _c('NoteModal', {
    attrs: {
      "reservation-data": _vm.getBookingData,
      "is-modify": true
    },
    on: {
      "update:reservationData": function updateReservationData($event) {
        _vm.getBookingData = $event;
      },
      "update:reservation-data": function updateReservationData($event) {
        _vm.getBookingData = $event;
      }
    }
  }) : _vm._e(), _c('SuggestShopVJModal', {
    attrs: {
      "passenger-data-to-add": _vm.paxLists,
      "airline-code": _vm.airlineCode
    },
    on: {
      "update:passengerDataToAdd": function updatePassengerDataToAdd($event) {
        _vm.paxLists = $event;
      },
      "update:passenger-data-to-add": function updatePassengerDataToAdd($event) {
        _vm.paxLists = $event;
      },
      "set-visible": _vm.onSetVisible
    }
  }), _c('CreateModal', {
    attrs: {
      "id": _vm.supportReqId,
      "booking": _vm.getBookingData
    },
    on: {
      "update:id": function updateId($event) {
        _vm.supportReqId = $event;
      },
      "update:booking": function updateBooking($event) {
        _vm.getBookingData = $event;
      },
      "set-id": function setId(id) {
        return _vm.supportReqId = id;
      }
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }